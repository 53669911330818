import React from "react"
import {Link} from "gatsby";
import './navbar.css';
import {StaticImage} from "gatsby-plugin-image";


export default function Navbar() {
    return (
        <>
            <nav>
                <Link to="/">Cześć!</Link>
                <Link to="/o-mnie">Poznajmy się</Link>
                <Link to="/artysci">Artyści</Link>
                <Link to="/oferta">Oferta</Link>
                <Link to="/galeria">Galeria</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/kontakt">Kontakt</Link>
            </nav>
            <StaticImage
                src='../../images/hero-menu.png'
                alt={"DJ Piotr Szymański"}
                className="nav__item"
            />
        </>
    )
}
