import React from "react";
import Social from '../social';
import "./footer.css";

const jsonLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "DJ Piotr Szymański - DJ / Konferansjer",
    "alternateName": "DJ Piotr Szymański",
    "url": "https://djpiotrszymanski.pl",
    "logo": "https://djpiotrszymanski.pl/logo.png",
    "sameAs": [
        "https://www.facebook.com/djpiotrszymanski",
        "https://www.instagram.com/djpiotrszymanski.pl",
        "https://djpiotrszymanski.pl"
    ]
};

export default function Footer() {
  return (
    <footer>
        <Social />
        <div className="footer-copyright">©2022 przez DJ Piotr Szymański. Wszelkie prawa zastrzeżone.</div>
    </footer>
  )
}
