import React from "react"
import './social.css'
import Spotify from '../../assets/spotify.svg';
import Fb from '../../assets/Fb.svg';
import Insta from '../../assets/Insta.svg';

export default function Social() {
  return (
      <div className="social">
          <a title={"Spotify - DJ Piotr Szymański"} href={"https://open.spotify.com/playlist/2FR0NdcXLZhfuyunEi0yuW?si=c4c90cff95084a20&nd=1"} target={"_blank"} rel={"nofollow, noindex"}> <Spotify /></a>
          <a title={"Facebook - DJ Piotr Szymański"} href={""} target={"_blank"} rel={"nofollow, noindex"}> <Fb /> </a>
          <a title={"Instagram - DJ Piotr Szymański"} href={"https://www.instagram.com/djpiotrszymanski.pl/"} target={"_blank"} rel={"nofollow, noindex"}> <Insta /> </a>
          <a href="https://www.weselezklasa.pl/ogloszenia-weselne/dj-wesele-na-najwyzszym-poziomie,19927/" rel={"nofollow"} target="_blank" title="Piotr Szymański - DJ / Konferansjer - Live Act"><img src="https://www.weselezklasa.pl/banery/Weselezklasa/logo26x32przezroczystetlobialewypelnienie.png" loading="lazy" height={"20"} alt="Piotr Szymański - DJ / Konferansjer - Live Act" border="0" /></a>
      </div>
  )
}
